import React from 'react';
import { Avatar } from 'antd';
import { codepen, codesandbox, figma, facebook, slack, linkedin, instagram } from '../../images/index';
import './integration.css';

const IntegrationBox = () => {
    const avatarUrls = [
        codepen,
        codesandbox,
        figma,
        facebook,
        slack,
        linkedin,
        instagram
    ];

    return (
        <>
            <div className="hexagon-container">
                {avatarUrls.map((avatar, id) => (
                    <Avatar key={id} className="integration-avatar hexagon" src={avatar} />
                ))}
            </div>
        </>
    );
};

export default IntegrationBox