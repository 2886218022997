import React, { useState, useContext } from 'react';
import { Menu, Button, Image } from 'antd';
import { MenuProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './navbar.css';
import logo from '../../images/logo.svg';
import { useMediaQuery } from 'react-responsive';
import { HomePageContext } from '../contexts/AppContext';
import NavbarCollapsed from '../navbar-collapsed/NavbarCollapsed';
import Lang from '../langdropdown/Lang';


const Navbar = () => {
    const [current, setCurrent] = useState('');
    const [open, setOpen] = useState(false);
    const isDesktop = useMediaQuery({ query: '(min-width: 993px)' });
    const isMobileTablet = useMediaQuery({ query: '(max-width: 992px' });
    const { translate } = useContext(HomePageContext);

    const items: MenuProps['items'] = [
        {
            label: `${translate('product')}`,
            key: 'products',
        },
        {
            label: `${translate('template')}`,
            key: 'templates',
        },
        {
            label: `${translate('pricing')}`,
            key: 'pricing',
        },
        {
            label: `${translate('integration')}`,
            key: 'integrations',
        }
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            <nav className='nav-container'>
                <div className='logo'>
                    <Image role="img" preview={false} src={logo} alt='formrelic logo in a blue color' />
                </div>
                {
                isDesktop && 
                   <> 
                       <Menu role="navigation" theme="light" mode="horizontal" onClick={onClick} selectedKeys={[current]} items={items} />
                       <Lang />
                   </>
                }
                {isMobileTablet && <Button role="button" aria-label={"menu"} type='primary' size='small' icon={<MenuOutlined />} onClick={showDrawer} ghost />}
                <NavbarCollapsed onClose={onClose} open={open} />
            </nav>
        </>
    );

}

export default Navbar