import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Image } from "antd";
import chooseImage from '../../images/choose.svg';
import shareImage from '../../images/shareImage.svg';
import editImage from '../../images/editImage.svg';


const images = [
    chooseImage,
    editImage,
    shareImage
];
const INTERVAL = 5000;

const ImageSlider = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const nextImage = () => {
            setIndex((index + 1) % images.length);
        };

        const timeoutId = setTimeout(() => {
            nextImage();
        }, INTERVAL);

        return () => clearTimeout(timeoutId);
    }, [index]);

    const props = useSpring({
        from: { opacity: 0, transition: ' opacity 2s ease 1s' },
        to: { opacity: 1, transition: ' opacity 2s ease 1s' }
    });

    return (
        <>
            <animated.div style={props}>
                <Image
                    role="img"
                    src={images[index]}
                    preview={false}
                    alt="a demo of how to create a form"
                />
            </animated.div>
        </>
    );
};

export default ImageSlider