import React from 'react';
import { Spin, Space } from 'antd';
import './loader.css';

const Loader = () => {
    return (
        <>
            <Space className='loader-spin'>
                <Spin  tip="Loading" size="large">
                    <div className="content" />
                </Spin>
            </Space>
        </>
    )
}

export default Loader