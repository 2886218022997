import React from 'react'
import HeaderImage from '../../images/HeaderImage.svg';
import { Image } from 'antd';

const Fallback = () => {
  return (
    <>
      <Image role="img" src={HeaderImage} preview={false} alt='form with emojis' />
    </>
  )
}

export default Fallback