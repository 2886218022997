import React, { useContext } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { HomePageContext } from '../contexts/AppContext';

const Lang = () => {
    const { lang, setLanguage } = useContext(HomePageContext);

    const handleLanguage = () => {
        const newLang = lang === 'en' ? 'fr' : 'en';
        setLanguage(newLang);
    };    

    return (
        <>
            <Button onClick={handleLanguage} icon={<GlobalOutlined />} type='primary'>
                {lang === 'en' ? 'Français-CANADA' : 'English'}
            </Button>
        </>
    )

}

export default Lang