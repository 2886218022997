import React, { useContext } from 'react';
import { Avatar, List } from 'antd';
import edit from '../../images/edit.svg';
import share from '../../images/share.svg';
import choose from '../../images/cursor.svg';
import { HomePageContext } from '../contexts/AppContext';
import './tutorial.css';

const TutorialBox = () => {
  const { translate } = useContext(HomePageContext);

    const tutorialCard = [
      {
        title: `${translate('choose_template')}`,
        description: `${translate('choose_template_text')}`,
        avatar: choose
      },
      {
        title: `${translate('edit_template')}`,
        description: `${translate('edit_template_text')}`,
        avatar: edit
      },
      {
        title: `${translate('share_template')}`,
        description: `${translate('share_template_text')}`,
        avatar: share
      }
    ];
  
    return (
      <>
            <List 
                itemLayout="horizontal" 
                dataSource={tutorialCard}
                renderItem={(item, id) => (
                    <List.Item key={id}>
                        <List.Item.Meta
                          avatar={<Avatar className='demo-avatar' src={item.avatar} />}
                          title={item.title}
                          description={item.description}
                        />
                    </List.Item>
                )}
            >
            </List>
      </>
    )
}

export default TutorialBox