import React, { Suspense, useContext } from 'react';
import { Layout, Space, Typography, Button } from 'antd';
import './header.css';
import Fallback from './Fallback';
import { HomePageContext } from '../contexts/AppContext';

const HeaderVideo = React.lazy(() => import('./VideoHeader'));

const Header = () => {
  const { Header } = Layout;
  const { translate } = useContext(HomePageContext);

  return (
    <>
      <Space>
        <Header>
         <Typography.Title role="heading" level={2} className='header-title'>{translate('header_title')}</Typography.Title>
         <Button role="button" aria-label={translate('get_started')} type='primary'>{translate('get_started')}</Button>
         <Suspense fallback={<Fallback />}>
           <HeaderVideo />
         </Suspense>
        </Header>
      </Space>
    </>
  )
}

export default Header