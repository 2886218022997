import React, { useContext } from 'react';
import { Card, Avatar } from 'antd';
import data from '../../images/data.svg';
import language from '../../images/language1.svg';
import microphone from '../../images/microphone1.svg';
import mobile from '../../images/mobile1.svg';
import { HomePageContext } from '../contexts/AppContext';
import './services.css';


const ServiceBox = () => {
  const { Meta } = Card;
  const { translate } = useContext(HomePageContext);

  const ServiceCards = [
    {
      description: `${translate('user_friendly')}`,
      avatar: mobile
    },
    {
      description: `${translate('shared_service')}`,
      avatar: data
    },
    {
      description: `${translate('voice_recognition')}`,
      avatar: microphone
    },
    {
      description: `${translate('form_translation')}`,
      avatar: language
    }
  ];

  return (
    <>
      {
        ServiceCards.map((card, id) => {
          return (<Card role="contentinfo" key={id} bordered={false}>
            <Meta avatar={<Avatar src={card.avatar} />} description={card.description} />
          </Card>)
        })
      }
    </>
  )
}

export default ServiceBox