import React, { useContext, useState } from 'react';
import { Drawer, MenuProps, Menu, Image } from 'antd';
import logo from '../../images/logo.svg';
import { HomePageContext } from '../contexts/AppContext';
import Lang from '../langdropdown/Lang';
import './navbarCollapsed.css'


const NavbarCollapsed = ({ onClose, open }: any) => {
  const [current, setCurrent] = useState('');
  const { translate } = useContext(HomePageContext);

  const items: MenuProps['items'] = [
    {
        label: `${translate('product')}`,
        key: 'products',
    },
    {
        label: `${translate('template')}`,
        key: 'templates',
    },
    {
        label: `${translate('pricing')}`,
        key: 'pricing',
    },
    {
        label: `${translate('integration')}`,
        key: 'integrations',
    }
];

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return (
    <>
      <Drawer placement='right' onClose={onClose} open={open}>
        <div className='logo-collapsed'>
          <Image role="img" preview={false} src={logo} alt='formrelic logo in blue' />
        </div>
        <Menu role="navigation" theme="light" mode="vertical" onClick={onClick} selectedKeys={[current]} items={items} />
        <Lang />
      </Drawer>
    </>
  )
}

export default NavbarCollapsed