import React, { createContext , useState, useEffect} from "react";
import { useQuery, gql } from '@apollo/client';
import { ChildProps, HomePageContextProps } from '../../interfaces/Assets';
import Loader from '../loader/Loader';
import Error from '../error/Error';



type Assets = {
    homePageAssets: {
        data: {
            id: number,
            attributes: {
                value: string,
                key: string,
                locale: string,
                localizations: {
                    data: {
                        id: number,
                        attributes: {
                        value: string,
                        key: string,
                        locale: string
                        }
                    }[]
                }
            }
        }[]
    }
}

export const HomePageContext = createContext<HomePageContextProps>({
   lang: '',
   translate: (key: string) => key,
   setLanguage: () => null
});

const Layout = ({children}: ChildProps) => {
    const [lang, setLang] = useState<string>('')

    const setLanguage = (lang: string) => {
        localStorage.setItem('language', lang)
        setLang(lang)
    }

    const setDefaultLanguage = (lang?: string | null): string | null => {
        const systemLanguage = typeof window !== "undefined" ? navigator.language.split("-")[0] : "null";
        if(typeof window !== "undefined") {
            localStorage.setItem("language", lang || systemLanguage);
        } else {
            return null;
        }

        return systemLanguage;
    };

    useEffect(() => {
        const getLang = (): string | null => {
            const ln_local =
                typeof window !== "undefined" ? localStorage.getItem("language") : null;
            const SYSTEM_LANGUAGE = ln_local || setDefaultLanguage();
            return SYSTEM_LANGUAGE;
        }
        const lang = getLang();        
        if (lang) setLanguage(lang);

    }, []);

    const getAssets = gql`
        query {
            homePageAssets(pagination: { page: 1, pageSize: 1000 }) {
                data {
                id
                attributes {
                    value
                    key
                    locale
                    localizations {
                        data {
                            id
                                attributes {
                                value
                                key
                                locale
                            }
                        }
                    }
                }
                }
            }
        } 
    `

    const { data, loading, error} = useQuery<Assets>(getAssets);
    

    if (loading) return <Loader />;
    if (error) return <Error message={error.message} />;
  
    const translate = (key: string): string => {

        const asset = data?.homePageAssets.data.find((a:any) => a.attributes.key === key);


        if (!asset) return "";
        let value = asset.attributes.value;

        if (lang !== "en") {
            const localAttr = asset.attributes.localizations.data.find((el:any) => key === el.attributes.key);
            if (localAttr) value = localAttr.attributes.value;
        } else {
            return value;
        }
        return value;
    }

    return (
        <HomePageContext.Provider value={{ lang, translate, setLanguage }}>
            <>
            
              {children}
            
            </>
        </HomePageContext.Provider>
    )
};

export default Layout