import React, { useContext } from 'react';
import { Layout, Space, Typography } from 'antd';
import './services.css';
import ServiceBox from './ServiceBox';
import { HomePageContext } from '../contexts/AppContext';

const Services = () => {
   const { Content } = Layout;
   const { translate } = useContext(HomePageContext);

  return (
    <>
      <Space>
        <Content role="main" id='offer-section-mobile'>
            <Typography.Title role="heading" level={5} className="section-title">{translate('offer')}</Typography.Title>
            <Typography.Title role="heading" level={3} className="section-caption">{translate('service_title')}</Typography.Title>
            <div role="region" className='card-box'>
              <ServiceBox />
            </div>
        </Content>
      </Space>
    </>
  )
}

export default Services