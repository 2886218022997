import { Image, Typography, Layout } from 'antd';
import errorImage from '../../images/errorImage.svg';
import React from 'react';


type ErrorText = {
    message: string
}

const Error = ({ message }: ErrorText) => {
    const { Content } = Layout;
    const Language = navigator.language.split("-")[0];

    return (
        <>
            <Content style={{ padding: '32px 32px 0 32px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography.Title style={{ textAlign: 'center', fontFamily: 'DM Sans' }} color='#001e3a' level={1}>{message}</Typography.Title>
                <Typography.Title style={{ textAlign: 'center', marginTop: '0', fontFamily: 'DM Sans' }} color='#001e3a' level={2}>
                    {Language === 'en' ? 'Ooops...Looks like something went wrong' : 'Ooops... On dirait que quelque chose s\'est mal passé'}
                </Typography.Title>
                <Typography.Paragraph color='#001e3a' style={{ textAlign: 'center', marginTop: '0', fontFamily: 'DM Sans', fontSize: '16px', fontWeight: '500' }} >
                    {Language === 'en' ? 'Sorry about that! Please visit our homePage to get where you need to go.' : 'Nous en sommes désolés ! Veuillez consulter notre page d\'accueil pour vous rendre là où vous voulez aller.'}
                </Typography.Paragraph>
                <Image src={errorImage} preview={false} loading='lazy' alt='people having an error' />
            </Content>
        </>
    )
}

export default Error