import React, { useContext } from 'react';
import { Layout, Space, Typography } from 'antd';
import TutorialBox from './TutorialBox';
import ImageSlider from './ImageSlider';
import { HomePageContext } from '../contexts/AppContext';
import './tutorial.css';

const Tutorial = () => {
    const { Content } = Layout;
    const { translate } = useContext(HomePageContext);

    return (
        <>
            <Space id='demo-main-container'>
                <Content role="main" id='demo-container'>
                    <div className='customer-content-texts'>
                        <Typography.Title role="heading" level={5} className="section-title-left">{translate('how_it_work')}</Typography.Title>
                        <Typography.Title role="heading" level={3} className="section-caption-left">{translate('how_it_work_title')}</Typography.Title>
                        <TutorialBox />
                    </div>
                    <div className='customer-image-animation'>
                        <ImageSlider />
                    </div>
                </Content>
            </Space>
        </>
    )
}

export default Tutorial